import React from "react";
import Box from "../../components/Box";
import ArrowRight from "../../icons/ArrowRight";
import ArrowLeft from "../../icons/ArrowLeft";
import createSingleComponent from "../../utils/createSingleComponent";

const HeadingAbout = createSingleComponent({
  name: "HeadingAbout",
  props: {
    display: "inline-flex",
    as: "h2",
    p: "16px",
    fontWeight: 700,
    fontSize: "25px",
    lineHeight: "28px",
    bg: "linear-gradient(90deg, rgba(255, 139, 2, 0.77) 0%, rgba(255, 178, 126, 0.75) 21.45%, rgba(255, 255, 255, 0) 99.06%, rgba(255, 178, 126, 0) 106.79%)",
  },
});

function HomeAbout() {
  return (
    <Box
      id="about"
      overflow="hidden"
      as="section"
      bg="primary"
      color="textOnPrimary"
      py="80px"
    >
      <Box
        mx="auto"
        maxWidth="1600px"
        px={{
          base: "40px",
          md: "60px",
          xl: "100px",
          "2xl": "130px",
        }}
        ml={{ md: "-200px", xl: "auto" }}
        display={{
          md: "flex",
        }}
      >
        <Box
          pt={{ base: "35px", xl: "0" }}
          mr="30px"
          display={{
            base: "none",
            md: "flex",
          }}
          color="#fff"
          fontSize={{ base: "220px", xl: "300px", "2xl": "350px" }}
          sx={{
            "& > *:not(:first-of-type)": {
              position: "relative",
              left: { base: "-80px", xl: "-100px" },
            },
          }}
        >
          <ArrowRight />
          <ArrowRight />
        </Box>
        <Box
          pt={{ xl: "30px" }}
          ml={{ md: "-70px" }}
          maxWidth={{ xl: "580px", "2xl": "620px" }}
        >
          <Box
            as="h2"
            fontWeight={700}
            fontSize={{
              base: "20px",
              xl: "35px",
            }}
            letterSpacing="0.02em"
            lineHeight="1.2"
            textAlign={{ md: "center" }}
          >
            Alibaba Cloud x KrASIA <br /> Global Startup Accelerator
          </Box>
          <Box
            textAlign={{ md: "center" }}
            as="p"
            mt={{ base: "45px", md: "22px" }}
            textStyle="text"
            fontSize={{
              base: "16px",
              xl: "14px",
            }}
            lineHeight={{
              base: "24px",
              xl: "20px",
            }}
          >
            In June 2021, Alibaba Cloud announced its new Project AsiaForward
            initiative to bring digital transformation to local enterprises in
            Asia and nurture fresh talent. As part of Project AsiaForward,
            Alibaba Cloud is launching the Alibaba Cloud x KrASIA Global Startup
            Accelerator. A series of Demo Days will be held, presenting startups
            the chance to be selected for the Global Startup Accelerator and
            become part of Alibaba Cloud’s global ecosystem.
          </Box>
          <Box
            textAlign={{ md: "center" }}
            as="p"
            mt="32px"
            fontSize={{
              base: "16px",
              xl: "14px",
            }}
            lineHeight={{
              base: "24px",
              xl: "20px",
            }}
          >
            The Alibaba Cloud x KrASIA Global Startup Accelerator Demo Days aims
            to seek out and empower high-potential budding startups in Asia who
            have dreams of becoming the next breakout story.
          </Box>
        </Box>
      </Box>
      <Box display="flex" position="relative" pt={{ xl: "110px" }}>
        <Box
          mx="auto"
          maxWidth="1600px"
          pr={{
            base: "40px",
            md: "60px",
            xl: "100px",
            "2xl": "130px",
          }}
          pl={{
            base: "40px",
            md: "60px",
            xl: "160px",
            "2xl": "50px",
          }}
          display={{ xl: "flex" }}
        >
          <Box mr={{ xl: "90px" }} mt={{ base: "60px", md: "100px", xl: "0" }}>
            <HeadingAbout>WHO SHOULD APPLY?</HeadingAbout>
            <Box
              as="p"
              mt="16px"
              fontSize={{
                base: "16px",
                xl: "14px",
              }}
              lineHeight={{
                base: "24px",
                xl: "20px",
              }}
              maxW={{ xl: "370px" }}
            >
              We invite startups from all stages and industries, with hopes to
              lead Asia's next stage of digital innovation, to propose their
              ideas and ambitions!
            </Box>
          </Box>
          <Box
            flex={{ xl: "1" }}
            mt={{ base: "60px", xl: "0" }}
            fontSize={{
              base: "16px",
              xl: "14px",
            }}
            lineHeight={{
              base: "24px",
              xl: "20px",
            }}
            maxWidth="500px"
          >
            <HeadingAbout>PRIZES AND BENEFITS</HeadingAbout>
            <Box pl={{ xl: "18px" }}>
              <Box mt="16px" as="p" fontWeight={700}>
                Upon successful registration and event organiser's confirmation
              </Box>
              <Box as="ul" pl="16px">
                <Box as="li">
                  USD 3,000 Alibaba Cloud credits after demo day
                </Box>
              </Box>
              <Box mt="2em" as="p" fontWeight={700}>
                Top 50
              </Box>
              <Box as="ul" pl="16px">
                <Box as="li">
                  1-to-1 technical support for setting up and migration
                </Box>
                <Box as="li">
                  Get the attention of notable regional VCs for potential
                  investments
                </Box>
              </Box>
              <Box mt="2em" as="p" fontWeight={700}>
                Country Stars (Demo Day Winners)
              </Box>
              <Box as="ul" pl="16px">
                <Box as="li">USD 60,000 Alibaba Cloud credits</Box>
                <Box as="li">
                  1-to-1 technical support for setting up and migration
                </Box>
                <Box as="li">
                  Get connected to top VCs in Southeast Asia and China
                </Box>
                <Box as="li">
                  Profile articles in English and Chinese by KrASIA and 36Kr
                  Global
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          position="relative"
          width="100px"
          pt={{ base: "35px", xl: "0" }}
          mr="30px"
          display={{
            base: "none",
            xl: "flex",
          }}
          justifyContent="flex-end"
        >
          <Box
            top={{
              base: "-10px",
              "2xl": "-80px",
            }}
            right={{
              base: "-480px",
              "2xl": "-450px",
            }}
            position="absolute"
            display={{
              base: "none",
              xl: "flex",
            }}
            justifyContent="flex-end"
            color="#fff"
            fontSize={{ xl: "380px", "2xl": "450px" }}
            sx={{
              "& > *:not(:first-of-type)": {
                position: "relative",
                left: { xl: "-150px" },
              },
            }}
          >
            <ArrowLeft />
            <ArrowLeft />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default HomeAbout;
