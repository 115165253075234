import { Link } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Link as IDLink } from "react-scroll";
import Box from "../Box";
import { Button } from "../Button";
import Img from "gatsby-image/withIEPolyfill";
export const menus = [
  {
    name: "About",
    id: "about",
  },
  {
    name: "Selection Process",
    id: "selection-process",
  },
  // {
  //   name: "Selection Criteria",
  //   id: "selection-criteria",
  // },
  {
    name: "Demo Day Schedules",
    id: "demo",
  },
  {
    name: "Event Highlights",
    id: "event",
  },
  {
    name: "Judges",
    id: "judges",
  },
  {
    name: "Partners",
    id: "partners",
  },
];

const LINK_KRASIA =
  "https://kr-asia.com?utm_source=ACGSAlandingpage&utm_medium=header_menu&utm_campaign=ACGSA";
// const LINK_SIGNUP =
//   "https://36krglobal.typeform.com/ACGSA-indo1?utm_source=site&utm_medium=header_menu&utm_campaign=ACGSA";

function Header({ link }: { link?: string }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Box
      zIndex="2000"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      as="header"
      bg="#000000"
      overflow="auto"
    >
      <Box
        mx="auto"
        bg="#000000"
        maxWidth="1600px"
        overflow="auto"
        width={{
          base: "1280px",
          xl: "100%",
        }}
        height="72px"
        pl={{
          base: "60px",
          md: "100px",
        }}
        pr={{
          base: "60px",
          md: "100px",
        }}
        display="flex"
        alignItems="center"
      >
        <Link
          href={`${LINK_KRASIA}`}
          target="_blank"
          _focus={{ outline: "none" }}
          mr="72px"
        >
          <Img
            fixed={data.file.childImageSharp.fixed}
            objectFit="cover"
            alt="KrAsia"
          />
        </Link>

        <Box as="nav" display="flex">
          {menus.map((f) => {
            return (
              <Box
                fontSize="14px"
                lineHeight="20px"
                mr="32px"
                cursor="pointer"
                fontWeight={700}
                color="#F2F2F2"
                key={f.name}
              >
                <IDLink to={f.id} smooth={true}>
                  {f.name}
                </IDLink>
              </Box>
            );
          })}
        </Box>
        <Box
          ml={{
            base: "0",
            xl: "auto",
          }}
        >
          <Button>
            <IDLink to={`${menus[2].id}`} smooth={true}>
              Sign up
            </IDLink>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
