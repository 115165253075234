import React from "react";
import {
  Button as ButtonCore,
  ButtonProps as ButtonCoreProps,
  IconButton,
  IconButtonProps as SA,
} from "@chakra-ui/button";
export type ButtonProps = ButtonCoreProps & {
  target?: string;
  href?: string;
  rel?: string;
};

export const Button = React.forwardRef<
  HTMLButtonElement | HTMLLinkElement,
  ButtonProps
>((props, ref) => {
  const { ...otherProps } = props;
  return <ButtonCore ref={ref as any} {...otherProps} />;
});

Button.displayName = "Button";

export type IconButtonProps = SA;

export { IconButton };
