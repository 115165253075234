import React from "react";
import Box from "../../components/Box";
import ArrowLeft from "../../icons/ArrowLeft";
import { Button } from "../../components/Button";
import ArrowRight from "../../icons/ArrowRight";
import { menus } from "../../components/Header";
import { Link as IDLink } from "react-scroll";

function ArrowDeco(props: { direction?: "horizontal" | "vertical" }) {
  const { direction = "vertical" } = props;

  if (direction === "vertical")
    return (
      <Box
        aria-hidden="true"
        color="#FF6A00"
        fontSize={{ base: "28px", xl: "36px" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          "& > *": {
            transform: "rotate(-90deg)",
          },
          "& > *:not(:first-of-type)": {
            mt: { base: "-5px", xl: "-8px" },
          },
        }}
      >
        <ArrowLeft />
        <ArrowLeft />
        <ArrowLeft />
        <ArrowLeft />
        <ArrowLeft />
        <ArrowLeft />
      </Box>
    );

  return (
    <Box
      aria-hidden="true"
      color="#FF6A00"
      fontSize={{ base: "28px", xl: "36px" }}
      display="flex"
      sx={{
        "& > *:not(:first-of-type)": {
          ml: { base: "-5px", xl: "-8px" },
        },
      }}
    >
      <ArrowRight />
      <ArrowRight />
      <ArrowRight />
      <ArrowRight />
      <ArrowRight />
      <ArrowRight />
    </Box>
  );
}

function HomeProcess() {
  const LINK_PROCESS_SIGNUP = `https://36krglobal.typeform.com/ACGSA-indo1?utm_source=site&utm_medium=selection_process&utm_campaign=ACGSA`;
  return (
    <Box
      id="selection-process"
      as="section"
      bg="#07A9FE"
      py={{
        base: "110px",
        xl: "150px",
      }}
      color="white"
      textAlign="center"
      overflow="hidden"
    >
      <Box position="relative" display="inline-block">
        <Box
          textAlign={{ base: "center", xl: "left" }}
          as="h2"
          fontSize={{ base: "43px", xl: "100px", "2xl": "120px" }}
          lineHeight={{ base: "46px", xl: "0.9" }}
          fontWeight={700}
          position={{ xl: "absolute" }}
          bottom={{ xl: "35px" }}
          left={{ xl: "0" }}
        >
          SELECTION <br /> PROCESS
        </Box>
        <Box
          mt={{ base: "80px", xl: "0" }}
          listStyleType="none"
          textAlign="center"
          justifyContent={{
            base: "center",
          }}
          alignItems={{
            xl: "flex-start",
          }}
          display="flex"
          flexDirection={{
            base: "column",
            xl: "row",
          }}
          role="list"
          fontWeight={700}
          fontSize={{ base: "25px", xl: "26px", "2xl": "30px" }}
          lineHeight={{ base: "30px", xl: "35px" }}
        >
          <Box display={{ xl: "inline-flex" }}>
            <Box role="listitem" display={{ xl: "flex" }} alignItems="center">
              <Box position="relative">
                REGISTER
                <Box
                  left="0"
                  bottom="-65px"
                  display={{ base: "none", xl: "block" }}
                  position={{ xl: "absolute" }}
                  fontWeight={{
                    base: 400,
                  }}
                  fontSize={{
                    base: "14px",
                  }}
                  lineHeight={{
                    base: "20px",
                  }}
                  as="ul"
                  listStyleType="none"
                >
                  <li>- Company name</li>
                  <li>- Contract details</li>
                  <li>- Corporate deck</li>
                </Box>
              </Box>
              <Box mt="24px" display={{ xl: "none" }}>
                <ArrowDeco />
              </Box>
              <Box ml="40px" display={{ base: "none", xl: "block" }}>
                <ArrowDeco direction="horizontal" />
              </Box>
            </Box>
            <Box
              alignItems="center"
              role="listitem"
              display={{ xl: "flex" }}
              mt={{ base: "24px", xl: "0" }}
              ml={{ xl: "36px" }}
              textAlign={{ xl: "left" }}
            >
              ASSESSMENT BY <br /> JUDGING PANEL
              <Box mt="24px" display={{ xl: "none" }}>
                <ArrowDeco />
              </Box>
              <Box ml="36px" display={{ base: "none", xl: "block" }}>
                <ArrowDeco direction="horizontal" />
              </Box>
            </Box>
          </Box>
          <Box width={{ xl: "200px" }} ml={{ xl: "20px" }}>
            <Box>
              <Box role="listitem" mt={{ base: "24px", xl: "14px" }}>
                DEMO DAY
                <Box mt={{ base: "24px", xl: "40px" }}>
                  <ArrowDeco />
                </Box>
              </Box>
              <Box
                ml={{ xl: "-200px" }}
                width={{ xl: "600px" }}
                role="listitem"
                mt={{ base: "24px", xl: "40px" }}
                px="8px"
              >
                <Box
                  fontSize={{ base: "20px", xl: "30px" }}
                  lineHeight={{ base: "25px", xl: "30px" }}
                  textAlign="center"
                  letterSpacing="0.05em"
                >
                  ALIBABA CLOUD X KRASIA <br />
                  GLOBAL STARTUP <br />
                  ACCELERATOR <br />
                </Box>
                <Box mt="16px" textStyle="text" textAlign="center" pr="24px">
                  <Box
                    fontSize={{ base: "16px", xl: "14px" }}
                    lineHeight={{
                      xl: "20px",
                      base: "22px",
                    }}
                  >
                    Up to 3 country stars will be selected from each Demo Day
                  </Box>
                </Box>
              </Box>
              <Box
                mt="30px"
                display={{ base: "none", xl: "flex" }}
                justifyContent="center"
              >
                <Button variant="ghost" size="lg">
                  <IDLink to={`${menus[2].id}`} smooth={true}>
                    Register now
                  </IDLink>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        mt="60px"
        justifyContent="center"
        display={{ base: "flex", xl: "none" }}
      >
        <Button variant="ghost">
          <IDLink to={`${menus[2].id}`} smooth={true}>
            Register now
          </IDLink>
        </Button>
      </Box>
    </Box>
  );
}

export default HomeProcess;
