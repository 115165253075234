import React from "react";
import format from "date-fns/format";
import axios from "axios";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import Box from "../../components/Box";
import LazyImage from "../../components/LazyImage";
import { Button } from "../../components/Button";

export interface Acf {
  data: ScheduleEntry[];
}

export interface ScheduleEntry {
  describeCountry: string;
  liveStart: string;
  liveEnd: string;
  regStart: string;
  regEnd: string;
  countryStars: string[];
  registration_link: string;
  description: string;
  description_2: string;
  status: "on going" | "past";
}

export interface Schedule {
  id: number;
  date: Date;
  date_gmt: Date;
  modified: Date;
  modified_gmt: Date;
  slug: string;
  status: string;
  type: string;
  link: string;
  title: {
    rendered: string;
  };
  template: string;
  acf: Acf;
}

interface Tab {
  slug: string;
  themeColor: string;
  borderColor: string;
  name: string;
  image: string;
  entries: TabEntry[];
}

interface TabEntry {
  regClose: number;
  regStart: number;
  liveStart: number;
  liveEnd: number;
  countryStarts?: string[];
  registration_link: string;
  description: string;
  description_2: string;
  status: "on going" | "past";
  describeCountry: string;
}

function formatDate(d: Date) {
  const date = d.getDate();

  const months = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "June",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  return `${format(d, "EEEE")} ${date > 9 ? date : `0${date}`} ${
    months[d.getMonth() as 0]
  } ${d.getFullYear()}`;
}

function formatTime(d: Date) {
  const hour = d.getHours();
  const minutes = d.getMinutes();

  let hourIndicator = "am";
  let hourDisplay = hour;
  let minutesDisplay: string | number = minutes;

  if (hour > 12) {
    hourIndicator = "pm";
    hourDisplay = hour - 12;
  }

  if (hour === 12) {
    hourDisplay = 0;
  }

  if (minutes < 10) {
    minutesDisplay = `0${minutes}`;
  }

  return `${hourDisplay}.${minutesDisplay}${hourIndicator}`;
}

async function fetchSchedule() {
  const result = await axios.get<Schedule[]>(
    "https://ali-bolt.kr-asia.com/wp-json/wp/v2/schedules"
  );

  const tabOn: Tab = {
    slug: "ongoing",
    name: "ONGOING",
    themeColor: "#000",
    borderColor: "#FF6902",
    entries: [],
    image: "/assets/images/demo/general.jpg",
  };

  const tabPast: Tab = {
    slug: "past",
    name: "PAST",
    themeColor: "#000",
    borderColor: "#FF6902",
    entries: [],
    image: "/assets/images/demo/general.jpg",
  };

  result.data.forEach((d) => {
    d.acf.data.forEach((dd) => {
      if (dd.status === "past") {
        tabPast.entries = [
          ...tabPast.entries,
          {
            regStart: new Date(dd.regStart).getTime(),
            liveEnd: new Date(dd.liveEnd).getTime(),
            regClose: new Date(dd.regEnd).getTime(),
            liveStart: new Date(dd.liveStart).getTime(),
            countryStarts: dd.countryStars,
            registration_link: dd.registration_link,
            description: dd.description,
            description_2: dd.description_2,
            status: dd.status,
            describeCountry: dd.describeCountry,
          },
        ];
      } else {
        tabOn.entries = [
          ...tabOn.entries,
          {
            regStart: new Date(dd.regStart).getTime(),
            liveEnd: new Date(dd.liveEnd).getTime(),
            regClose: new Date(dd.regEnd).getTime(),
            liveStart: new Date(dd.liveStart).getTime(),
            countryStarts: dd.countryStars,
            registration_link: dd.registration_link,
            description: dd.description,
            description_2: dd.description_2,
            status: dd.status,
            describeCountry: dd.describeCountry,
          },
        ];
      }
    });
  });

  return [tabOn, tabPast];
}

function HomeSchedule() {
  const [tabs, setTabs] = React.useState<Tab[]>([]);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [bannerHeight, setBannerHeight] = React.useState(0);
  const rowsRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    fetchSchedule()
      .then((r) => {
        setTabs(r);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, []);

  React.useLayoutEffect(() => {
    setBannerHeight(0);
    function calc() {
      setTimeout(() => {
        if (rowsRef.current) {
          setBannerHeight(
            document.getElementById(`tab-content-${tabIndex}`)?.clientHeight ||
              0
          );
        }
      }, 100);
    }

    calc();

    window.addEventListener("resize", calc);

    return () => {
      window.removeEventListener("resize", calc);
    };
  }, [rowsRef, tabIndex, tabs]);

  return (
    <Box id="demo" as="section" bg="white" py={{ base: "50px", xl: "50px" }}>
      <Box
        textAlign="center"
        as="h2"
        fontWeight={700}
        fontSize={{ base: "20px", md: "25px", xl: "26px" }}
        lineHeight="38px"
        letterSpacing="0.02em"
        color="#222"
      >
        DEMO DAYS
      </Box>
      <Tabs
        onChange={(index) => setTabIndex(index)}
        mt={{ base: "26px", md: "31px" }}
        maxWidth="1300px"
        mx="auto"
      >
        {/* close Tabs */}
        {/* <Box overflow="auto">
          <Box
            mx="auto"
            maxWidth="1300px"
            width={{ base: Math.max(1300, tabs.length * 300) }}
            px={{ base: "20px", md: "100px" }}
            borderBottom="1px solid #CCCCCC"
            borderColor="#CCCCCC"
          >
            <TabList
              minHeight="40px"
              borderBottom="none"
              justifyContent={{ xl: "center" }}
            >
              <Tab
                fontSize="16px"
                lineHeight="32px"
                color="#888888"
                letterSpacing="0.02em"
                height="40px"
                mr="30px"
                outline="none"
                sx={{
                  "&:focus": {
                    boxShadow: "none",
                  },
                  "&[aria-selected='true']": {
                    fontWeight: 700,
                    color: "black",
                    borderColor: "black",
                    borderBottomWidth: "3px",
                  },
                }}
              >
                ONGOING
              </Tab>
              <Tab
                fontSize="16px"
                lineHeight="32px"
                color="#888888"
                letterSpacing="0.02em"
                height="40px"
                mr="30px"
                outline="none"
                sx={{
                  "&:focus": {
                    boxShadow: "none",
                  },
                  "&[aria-selected='true']": {
                    fontWeight: 700,
                    color: "black",
                    borderColor: "black",
                    borderBottomWidth: "3px",
                  },
                }}
              >
                PAST
              </Tab>
            </TabList>
          </Box>
        </Box> */}

        <TabPanels ref={rowsRef} mt="50px" mx="auto" maxWidth="1300px">
          {tabs.map((t, idx) => {
            return (
              <TabPanel px="0" key={t.name}>
                <Box display="flex">
                  <Box bg={t.borderColor}>
                    <Box
                      height={bannerHeight}
                      minHeight={{ base: "240px" }}
                      position="relative"
                      flexShrink={0}
                      overflow="hidden"
                    >
                      <Box
                        width="100%"
                        height="100%"
                        overflow="hidden"
                        sx={{
                          "&  img": {
                            maxHeight: "100%",
                            maxWidth: {
                              base: "90px",
                              md: "170px",
                              xl: "300px",
                            },
                          },
                        }}
                      >
                        <Box as="img" src={t.image} alt={t.name} />
                      </Box>
                    </Box>
                  </Box>
                  <Box flex="1" id={`tab-content-${idx}`}>
                    {[...t.entries].map((entry, i) => {
                      const liveStart = new Date(entry.liveStart);
                      const liveEnd = new Date(entry.liveEnd);
                      const themeColor = t.themeColor;
                      const now = new Date();

                      const isNotStarted = now.getTime() < entry.regStart;
                      const isClose = now.getTime() > entry.regClose;

                      const isOpen =
                        entry.regClose > now.getTime() &&
                        now.getTime() > entry.regStart;

                      return (
                        <Box
                          key={i}
                          pt={{ base: "20px", md: "40px" }}
                          pb={{ base: "20px", md: "30px" }}
                          ml={{ base: "3px", md: "20px" }}
                          borderBottom="1px solid"
                          borderColor="black"
                        >
                          <Box
                            fontSize="14px"
                            lineHeight="20px"
                            letterSpacing="0.02em"
                            display="flex"
                            pl={{ base: "16px", xl: "50px" }}
                            pr={{ base: "24px", xl: "50px" }}
                            color={themeColor}
                          >
                            <Box
                              width={{ md: "320px" }}
                              pr={{ base: "18px", md: "100px" }}
                            >
                              <b>{formatDate(liveStart)}</b>
                              <p>
                                {formatTime(liveStart)} - {formatTime(liveEnd)}
                              </p>
                            </Box>
                            <Box flex="1">
                              {isOpen && (
                                <Box
                                  display={{ xl: "flex" }}
                                  alignItems="center"
                                >
                                  <Box as="p" width={{ xl: "400px" }}>
                                    {/*Registration in progress.{" "}*/}
                                    {/*<Box as="span" display={{ xl: "block" }} />*/}
                                    {/*Submission will close on{" "}*/}
                                    {/*<span>{formatDate(regClose)}</span>.*/}
                                    {entry.description}
                                  </Box>
                                  <Box
                                    ml={{ xl: "auto" }}
                                    mt={{ base: "30px", xl: "0" }}
                                    textAlign={{ md: "right" }}
                                  >
                                    <Button
                                      as="a"
                                      href={entry.registration_link}
                                      target="_blank"
                                      color="white"
                                      bg="#FF6A00"
                                    >
                                      Register
                                    </Button>
                                  </Box>
                                </Box>
                              )}
                              {!isOpen && isNotStarted && (
                                <Box
                                  display={{ xl: "flex" }}
                                  alignItems="center"
                                >
                                  <Box as="p" width={{ xl: "400px" }}>
                                    {entry.description}
                                  </Box>
                                </Box>
                              )}
                              {entry.countryStarts && isClose && (
                                <Box as="p" fontWeight="bold">
                                  {entry.describeCountry}:
                                </Box>
                              )}
                              {!entry.countryStarts && isClose && (
                                <p>{entry.description_2}</p>
                              )}
                              {entry.countryStarts && (
                                <Box
                                  overflow="auto"
                                  display="flex"
                                  flexWrap="wrap"
                                  sx={{
                                    "& img": {
                                      height: {
                                        base: "50px",
                                        xl: "60px",
                                      },
                                    },
                                    "& > *": {
                                      mt: "10px",
                                      display: "flex",
                                    },

                                    "& *:nth-of-type(3n+2)": {
                                      justifyContent: "center",
                                    },

                                    "& *:nth-of-type(3n+3)": {
                                      justifyContent: "flex-end",
                                    },
                                  }}
                                >
                                  {entry.countryStarts.map((c) => {
                                    return (
                                      <Box key={c} flex="33.33%">
                                        <LazyImage src={c} alt={c} />
                                      </Box>
                                    );
                                  })}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default HomeSchedule;
