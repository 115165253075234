import React from "react";
import Box from "../../components/Box";
import LazyImage from "../../components/LazyImage";
import { Button } from "../../components/Button";
import axios, { AxiosError } from "axios";

interface EventData {
  id: number;
  date: Date;
  date_gmt: Date;
  guid: GUID;
  modified: Date;
  modified_gmt: Date;
  slug: string;
  status: string;
  type: string;
  link: string;
  title: GUID;
  template: string;
  acf: Acf;
  _links: Links;
}

interface Links {
  self: About[];
  collection: About[];
  about: About[];
  "wp:attachment": About[];
  curies: Cury[];
}

interface About {
  href: string;
}

interface Cury {
  name: string;
  href: string;
  templated: boolean;
}

interface Acf {
  category: string;
  author: string;
  publishDate: string;
  thumbnail: string;
  link: string;
}

export interface GUID {
  rendered: string;
}

async function fetchSchedule(page: number, perPage: number) {
  const result = await axios.get<EventData[]>(
    `https://ali-bolt.kr-asia.com/wp-json/wp/v2/events?page=${page}&per_page=${perPage}`
  );

  return result.data;
}

function HomeEvent() {
  const [posts, setPosts] = React.useState<EventData[]>([]);
  const [pagination, setPagination] = React.useState<{
    page: number;
    perPage: number;
    loadMore: boolean;
    isLoading: boolean;
  }>({
    isLoading: false,
    page: 1,
    perPage: 6,
    loadMore: true,
  });

  React.useEffect(() => {
    setPagination((pa) => {
      return {
        ...pa,
        isLoading: true,
      };
    });
    fetchSchedule(pagination.page, pagination.perPage)
      .then((posts) => {
        setPosts((p) => {
          return [...p, ...posts];
        });
        setPagination((pa) => {
          return {
            ...pa,
            loadMore: posts.length === pagination.perPage,
            isLoading: false,
          };
        });
      })
      .catch((e) => {
        if (e.isAxiosError) {
          const err = e as AxiosError;
          if (err.response?.data.code === "rest_post_invalid_page_number") {
            setPagination((pa) => {
              return {
                ...pa,
                loadMore: false,
                isLoading: false,
              };
            });
          }
        } else {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      });
  }, [pagination.perPage, pagination.page]);

  function handleLoadMore() {
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    });
  }

  return (
    <Box id="event" as="section" pt={{ base: "20px", xl: "60px" }} pb="120px">
      <Box
        textAlign="center"
        as="h2"
        fontWeight={700}
        fontSize={{ base: "20px", md: "25px", xl: "26px" }}
        lineHeight={{ base: "38px", xl: "38px" }}
        letterSpacing="0.02em"
        color="#222"
      >
        EVENT HIGHLIGHTS
      </Box>
      <Box
        mt="60px"
        display="flex"
        flexDirection={{ base: "column", xl: "row" }}
        justifyContent={{ base: "center", xl: "flex-start" }}
        flexWrap="wrap"
        maxWidth="1200px"
        mx="auto"
      >
        {posts.map((article, i) => {
          return (
            <Box
              key={article.id}
              flexBasis={{ xl: "33.33%" }}
              flexShrink={{ xl: "0" }}
              mt={{ base: "30px", xl: "50px" }}
              as="article"
              display={{ base: "inline-flex", xl: "block" }}
              px="16px"
            >
              <Box
                as="a"
                target="_blank"
                href={article.acf.link}
                flexShrink={0}
                sx={{
                  "& img": {
                    objectFit: "cover",
                    width: { base: "140px", md: "160px", xl: "100%" },
                    height: { base: "120px", md: "140px", xl: "200px" },
                  },
                  "& span": {
                    width: "100%",
                    display: "block",
                  },
                }}
              >
                <LazyImage
                  src={article.acf.thumbnail}
                  alt={article.title.rendered}
                />
              </Box>
              <Box
                mt={{ xl: "20px" }}
                ml={{ base: "32px", xl: "0" }}
                maxWidth="400px"
              >
                <Box color="black" as="p" fontSize="12px" letterSpacing="0.1em">
                  {article.acf.category}
                </Box>
                <Box as="a" target="_blank" href={article.acf.link}>
                  <Box
                    as="h3"
                    fontWeight="bold"
                    color="black"
                    fontSize={{ base: "16px", md: "23px", xl: "21px" }}
                    lineHeight={{ base: "21px", md: "28px", xl: "24px" }}
                  >
                    {article.title.rendered}
                  </Box>
                </Box>
                <Box
                  mt={{ base: "4px", xl: "30px" }}
                  as="p"
                  color="#888888"
                  fontSize="12px"
                >
                  {article.acf.author}
                </Box>
                <Box
                  as="p"
                  color="#888888"
                  fontSize="12px"
                  lineHeight={{
                    base: "16px",
                  }}
                >
                  {new Date(article.acf.publishDate).toLocaleDateString(
                    undefined
                  )}
                </Box>
              </Box>
            </Box>
          );
        }, [])}
      </Box>
      {pagination.loadMore && (
        <Box mt="60px" textAlign="center">
          <Button
            disabled={pagination.isLoading}
            isLoading={pagination.isLoading}
            borderColor="primary"
            color="primary"
            variant="ghost"
            onClick={handleLoadMore}
            loadingText="Loading..."
          >
            View more
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default HomeEvent;
