import React from "react";
import Box from "../../components/Box";
import LazyImage from "../../components/LazyImage";
import axios from "axios";

export interface Judge {
  id: number;
  title: {
    rendered: string;
  };
  template: string;
  acf: {
    position: string;
    companyName: string;
    avatar: string;
  };
}

const judges = [
  {
    name: "Chen Tao",
    position: "Chairman & CEO",
    company: "Bingqing Plum Wine",
  },
  {
    name: "Chen Tao",
    position: "Chairman & CEO",
    company: "Bingqing Plum Wine",
  },
  {
    name: "Chen Tao",
    position: "Chairman & CEO",
    company: "Bingqing Plum Wine",
  },
  {
    name: "Chen Tao",
    position: "Chairman & CEO",
    company: "Bingqing Plum Wine",
  },
  {
    name: "Chen Tao",
    position: "Chairman & CEO",
    company: "Bingqing Plum Wine",
  },
  {
    name: "Chen Tao",
    position: "Chairman & CEO",
    company: "Bingqing Plum Wine",
  },
];

async function fetchJudge() {
  const result = await axios.get<Judge[]>(
    `https://ali-bolt.kr-asia.com/wp-json/wp/v2/judges?per_page=50`
  );

  return result.data;
}

function HomeJudges() {
  const [juds, setJuds] = React.useState<Judge[]>([]);

  React.useEffect(() => {
    fetchJudge()
      .then((d) => {
        setJuds(d);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, []);

  return (
    <Box
      id="judges"
      as="section"
      bg="primary"
      pt={{ base: "100px", xl: "170px" }}
      pb="50px"
      position="relative"
    >
      <Box
        bg="#07AD63"
        position="absolute"
        top={{
          base: "-28px",
          xl: "-68px",
        }}
        right={{
          base: "34px",
          xl: "60px",
        }}
        display={{ base: "none", md: "flex", xl: "flex" }}
        justifyContent="center"
        alignItems="center"
        width={{
          base: "113px",
          xl: "225px",
        }}
        height={{
          base: "113px",
          xl: "225px",
        }}
      >
        <Box
          as="svg"
          width={{ base: "80px", xl: "160px" }}
          viewBox="0 0 169 119"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M84.6101 0.429984C118.85 -0.540016 154.11 21.27 167.89 57.6C168.29 58.65 168.41 60.11 168 61.11C154.06 94.84 129.06 114.85 92.7001 118.4C47.8601 122.78 13.9201 94.79 1.34011 61.35C0.950109 60.3 0.850109 58.84 1.27011 57.84C14.8001 25.15 38.9601 5.95998 74.0801 0.789984C75.0901 0.639984 76.1001 0.469984 77.1101 0.449984C79.6101 0.399984 82.1101 0.429984 84.6101 0.429984ZM80.5101 104.05C113.1 104.04 139.17 87.61 151.6 61.93C152.48 60.11 152.4 58.67 151.5 56.95C138.74 32.42 118.75 18.23 91.2001 15.39C60.5001 12.22 30.6101 29.54 17.4601 57.52C16.7401 59.05 16.8201 60.23 17.5501 61.65C31.5501 89 54.1901 102.65 80.5101 104.05Z"
            fill="white"
          />
          <path
            d="M76.2005 31.15C89.1105 27.12 103.411 32.85 110.261 44.55C117.251 56.47 115.001 71.78 104.881 81.25C94.7906 90.69 79.3405 91.86 67.9005 84.11C56.2005 76.18 52.7305 61.63 56.3105 51.26C60.3405 57.82 66.0305 60.82 73.5705 58.94C79.2605 57.52 82.9505 53.67 84.1505 47.89C85.6705 40.57 82.6805 35.14 76.2005 31.15Z"
            fill="white"
          />
        </Box>
      </Box>
      <Box
        textAlign="center"
        as="h2"
        fontWeight={700}
        fontSize={{ base: "20px", md: "25px", xl: "26px" }}
        lineHeight={{ base: "38px", xl: "38px" }}
        letterSpacing="0.02em"
        color="textOnPrimary"
      >
        PANEL OF JUDGES
      </Box>
      <Box
        mt={{ base: "20px", xl: "60px" }}
        maxWidth={{ base: "450px", xl: "1400px" }}
        mx="auto"
        px="24px"
        display="flex"
        justifyContent={{
          base: "center",
        }}
        flexDirection={{
          base: "column",
          xl: "row",
        }}
        flexWrap={{
          xl: "wrap",
        }}
      >
        {juds.map((j, i) => {
          return (
            <Box
              flexBasis={{
                xl: "33.33%",
              }}
              flexShrink={{
                xl: 0,
              }}
              minHeight="220px"
              mt="30px"
              key={j.id}
              as="article"
              px={{ xl: "20px" }}
            >
              <Box
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                bg="white"
                position="relative"
                display="flex"
                pb={{ base: "24px", xl: "40px" }}
              >
                <Box
                  position="absolute"
                  top="-16px"
                  left="-4px"
                  width="14px"
                  height="190px"
                  bg="primary"
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                />
                <Box pt="55px" color="primary" pl="40px">
                  <Box
                    as="h3"
                    fontSize={{ base: "14px", md: "16px" }}
                    lineHeight={{ base: "19px", md: "24px" }}
                    fontWeight={700}
                  >
                    {j.title.rendered}
                  </Box>
                  <Box
                    mt="25px"
                    as="p"
                    fontSize={{ base: "14px", md: "16px" }}
                    lineHeight={{ base: "19px", md: "24px" }}
                  >
                    {j.acf.position}
                  </Box>
                  <Box
                    fontSize={{ base: "14px", md: "16px" }}
                    lineHeight={{ base: "19px", md: "24px" }}
                    as="p"
                  >
                    {j.acf.companyName}
                  </Box>
                </Box>
                <Box
                  ml="auto"
                  flexShrink={0}
                  sx={{
                    "& img": {
                      objectFit: "cover",
                      width: { base: "150px", md: "170px" },
                      height: { base: "170px", md: "170px" },
                      borderBottom: "3px solid",
                      borderColor: "primary",
                    },
                  }}
                >
                  <LazyImage src={j.acf.avatar} alt={j.title.rendered} />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default HomeJudges;
