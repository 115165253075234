import React from "react";
import Box, { BoxProps } from "../../components/Box";
import { Button } from "../../components/Button";
import LazyImage from "../../components/LazyImage";
import HomeSchedule from "../HomeSchedule";

function BoxText(
  props: BoxProps & {
    title: string;
    text: string;
  }
) {
  const { title, text, ...others } = props;
  return (
    <Box {...others}>
      <BorderDeco />
      <Box
        mt="16px"
        as="p"
        fontSize={{ base: "18px", xl: "20px" }}
        lineHeight={{
          base: "26px",
          xl: "30px",
        }}
      >
        {title}
      </Box>
      <Box
        as="p"
        mt="14px"
        fontSize={{ base: "14px" }}
        lineHeight={{
          base: "20px",
        }}
      >
        {text}
      </Box>
    </Box>
  );
}

function Bracket() {
  return (
    <Box
      as="svg"
      height={{ base: "219", xl: "270px" }}
      viewBox="0 0 87 219"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M90.2684 121.703C84.3439 122.126 79.5197 122.888 75.7957 123.988C72.1564 125.089 69.2788 126.781 67.1629 129.066C65.1317 131.352 63.7352 134.314 62.9734 137.953C62.2117 141.592 61.8309 146.12 61.8309 151.537L61.7039 175.404C61.7039 183.952 60.646 191.02 58.5301 196.605C56.4988 202.191 53.325 206.592 49.0086 209.809C44.6922 213.109 39.2332 215.395 32.6317 216.664C26.1147 218.018 18.4129 218.695 9.52618 218.695H0.639465V195.717H6.47931C12.1499 195.717 16.6779 195.294 20.0633 194.447C23.4487 193.601 25.9878 192.374 27.6805 190.766C29.4578 189.073 30.6004 187.042 31.1082 184.672C31.616 182.217 31.9123 179.382 31.9969 176.166L32.8856 139.223C32.9702 134.652 33.4357 130.632 34.282 127.162C35.1284 123.692 36.4826 120.772 38.3445 118.402C40.2912 115.948 42.8725 114.001 46.0887 112.562C49.3048 111.124 53.3673 110.108 58.2762 109.516C53.452 108.923 49.4318 107.908 46.2156 106.469C42.9995 104.945 40.4181 102.956 38.4715 100.502C36.5249 98.0475 35.1284 95.1276 34.282 91.7422C33.4357 88.3568 32.9702 84.4212 32.8856 79.9355L31.9969 46.4199C31.9123 42.696 31.743 39.3952 31.4891 36.5176C31.2352 33.64 30.3465 31.2279 28.8231 29.2812C27.2996 27.3346 24.8029 25.8535 21.3328 24.8379C17.9474 23.8223 12.9962 23.3145 6.47931 23.3145H0.639465V0.462891H9.52618C18.4129 0.462891 26.1147 1.13997 32.6317 2.49414C39.2332 3.76367 44.6499 6.04883 48.8817 9.34961C53.1981 12.5658 56.4142 16.9668 58.5301 22.5527C60.646 28.1387 61.7039 35.1634 61.7039 43.627L61.8309 67.4941C61.8309 72.9108 62.2117 77.4388 62.9734 81.0781C63.7352 84.7174 65.1317 87.6797 67.1629 89.9648C69.2788 92.25 72.1564 93.985 75.7957 95.1699C79.5197 96.3548 84.3439 97.1165 90.2684 97.4551V121.703Z"
        fill="#FF6902"
      />
    </Box>
  );
}

function BracketLeft() {
  return (
    <Box
      as="svg"
      height={{ base: "219", xl: "270px" }}
      viewBox="0 0 89 219"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M-1.26825 97.4551C4.65623 97.1165 9.43813 96.3548 13.0775 95.1699C16.7168 93.985 19.5521 92.25 21.5833 89.9648C23.6146 87.6797 25.011 84.7174 25.7728 81.0781C26.5345 77.4388 27 72.9108 27.1693 67.4941C27.3385 63.4316 27.4232 59.4538 27.4232 55.5605C27.4232 51.6673 27.4232 47.6895 27.4232 43.627C27.2539 35.1634 28.1849 28.1387 30.2161 22.5527C32.332 16.9668 35.5482 12.5658 39.8646 9.34961C44.181 6.04883 49.64 3.76367 56.2415 2.49414C62.8431 1.13997 70.6295 0.462891 79.6009 0.462891H88.2337V23.3145H82.5208C76.0039 23.3145 71.0104 23.8223 67.5403 24.8379C64.1549 25.8535 61.6582 27.3346 60.0501 29.2812C58.5267 31.2279 57.638 33.64 57.3841 36.5176C57.2148 39.3952 57.0879 42.696 57.0032 46.4199L56.1146 79.9355C56.0299 84.4212 55.5644 88.3568 54.7181 91.7422C53.8717 95.1276 52.4752 98.0475 50.5286 100.502C48.582 102.956 46.0006 104.945 42.7845 106.469C39.5683 107.908 35.5482 108.923 30.7239 109.516C35.6328 110.108 39.6953 111.124 42.9114 112.562C46.1276 114.001 48.709 115.948 50.6556 118.402C52.6022 120.772 53.9564 123.692 54.7181 127.162C55.5644 130.632 56.0299 134.652 56.1146 139.223L57.0032 176.166C57.0879 179.382 57.3841 182.217 57.8919 184.672C58.3997 187.042 59.5 189.073 61.1927 190.766C62.97 192.374 65.5091 193.601 68.8099 194.447C72.1953 195.294 76.7656 195.717 82.5208 195.717H88.2337V218.695H79.6009C70.6295 218.695 62.8854 218.018 56.3685 216.664C49.8515 215.395 44.4349 213.109 40.1185 209.809C35.8867 206.592 32.7129 202.191 30.597 196.605C28.4811 191.02 27.4232 183.952 27.4232 175.404L27.1693 151.537C27.0846 146.12 26.6614 141.592 25.8997 137.953C25.138 134.314 23.7415 131.352 21.7103 129.066C19.679 126.781 16.8014 125.089 13.0775 123.988C9.43813 122.888 4.65623 122.126 -1.26825 121.703V97.4551Z"
        fill="#FF6902"
      />
    </Box>
  );
}

function BorderDeco(props: BoxProps) {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <Box borderRadius="50%" bg="#FF6902" w="24px" h="24px" />
      <Box ml="24px" w="180px" border="1px dashed #FF6902" />
    </Box>
  );
}

function HomeSelection() {
  return (
    <Box
      id="selection-criteria"
      as="section"
      bg="white"
      pt={{ base: "90px", xl: "130px" }}
      pb={{ base: "70px", xl: "200px" }}
    >
      <Box maxWidth="1400px" mx="auto">
        <Box
          px={{
            base: "50px",
            xl: "24px",
          }}
          textAlign={{
            base: "left",
            xl: "center",
          }}
          as="h2"
          fontSize={{ base: "20px", xl: "40px" }}
          lineHeight={{ base: "38px", xl: "64px" }}
          fontWeight={700}
        >
          SELECTION CRITERIA
        </Box>
        <Box
          mt={{ base: "30px", xl: "56px" }}
          px={{ base: "50px", xl: "24px" }}
          display={{
            xl: "flex",
          }}
          justifyContent="center"
        >
          <BoxText
            width={{ xl: "33.33333%" }}
            title="POTENTIAL OF EXPANDING OVERSEAS"
            text="Based on the company's demonstrated market research of overseas demand and plans to penetrating overseas markets."
          />
          <BoxText
            ml={{ xl: "50px" }}
            width={{ xl: "33.33333%" }}
            mt={{
              base: "40px",
              xl: 0,
            }}
            title="FEASIBILITY OF BUSINESS MODEL"
            text={`Based on the defensibility and scalability of startups' revenue models.`}
          />
          <BoxText
            ml={{ xl: "50px" }}
            width={{ xl: "33.33333%" }}
            mt={{
              base: "40px",
              xl: 0,
            }}
            title="DIFFERENTIATION"
            text={`Based on the novelty of idea and potential to create meaningful impact in the startups' respective industry.`}
          />
        </Box>
      </Box>
      <Box pt="60px">
        <HomeSchedule />
      </Box>
      <Box
        mt={{ base: "100px", xl: "200px" }}
        overflow={{ base: "hidden", xl: "visible" }}
        mx={{ xl: "auto" }}
        maxWidth={{ xl: "1100px" }}
        position="relative"
      >
        <Box
          position={{ base: "relative", xl: "absolute" }}
          right={{ xl: "180px" }}
          top={{ xl: "-120px" }}
          zIndex="1"
          height="400px"
          display="flex"
          justifyContent="center"
        >
          <LazyImage src="/assets/images/qr-phone.png" alt="" />
        </Box>
        <Box
          pl="30px"
          position="relative"
          top={{ base: "-40px", xl: "0" }}
          pr={{
            sm: "30px",
          }}
        >
          <Box
            mx={{ base: "auto" }}
            maxWidth="1100px"
            pt="120px"
            minHeight={{ base: "250px", xl: "190px" }}
            bg="#07AD63"
            borderRadius="16px"
            width={{ base: "500px", sm: "100%" }}
            color="#fff"
          />
          <Box
            maxWidth={{ xl: "550px" }}
            color="#fff"
            top={{ base: "120px", xl: "40px" }}
            left={{ base: "60px", xl: "120px" }}
            right={{ sm: "100px" }}
            position="absolute"
            as="p"
            fontWeight="bold"
            fontSize={{ base: "19px", xl: "25px" }}
            lineHeight={{ base: "23px", xl: "33px" }}
          >
            Scan this QR code to join the program’s DingTalk Group and stay
            updated with all program-related announcements.
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default HomeSelection;
