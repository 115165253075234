import React from "react";
import { Helmet } from "react-helmet";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "../theme";
import { CSSReset } from "@chakra-ui/css-reset";
import Box from "../components/Box";

export interface MainProps {
  children?: React.ReactNode;
}

function Main(props: MainProps) {
  const { children } = props;
  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <title>Alibaba Cloud Asia Forward 2021</title>
        <meta
          name="description"
          content="Helping Asian start-ups seize the opportunity of the golden age of digital innovation to grow rapidly. Alibaba Cloud Asia Forward 2021 Demo Day aims to seek out and empower high-potential budding startups in Southeast Asia who have dreams of becoming the next breakout story."
        />
        <meta charSet="utf-8" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Helmet>
      <CSSReset />
      <Box textStyle="text" as="main">
        {children}
      </Box>
    </ChakraProvider>
  );
}

export default Main;
