import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "75em",
  "2xl": "96em",
});

const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      body: {
        textStyles: "text",
        fontFamily: '"Helvetica", sans-serif',
      },
    },
  },
  sizes: {
    container: {
      xl: 1400,
    },
  },
  colors: {
    white: "#fff",
    black: "#000",
    title: "#222222",
    text: "#000",
    primary: "#FF6900",
    textOnPrimary: "#fff",
  },
  zIndices: {
    modal: 1700,
  },
  textStyles: {
    text: {
      fontWeight: "400",
      fontSize: {
        base: "16px",
      },
      lineHeight: {
        base: "24px",
      },
    },
  },
  components: {
    Input: {
      sizes: {
        md: {
          borderRadius: 0,
          height: "48px",
        },
        lg: {
          borderRadius: 0,
          height: "56px",
        },
      },
      variants: {},
    },
    Button: {
      baseStyle: {
        bg: "white",
        color: "black",

        borderRadius: "90px",
      },
      sizes: {
        md: {
          pl: "40px",
          pr: "40px",
          height: "40px",
        },
        lg: {
          pl: "40px",
          pr: "40px",
          height: "48px",
        },
      },
      variants: {
        ghost: {
          border: "2px solid",
          borderColor: "inherit",
          bg: "transparent",
          color: "inherit",
        },
        icon: {
          bg: "transparent",
        },
      },
    },
    Table: {
      baseStyle: {
        th: {
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
      sizes: {
        md: {
          th: {
            px: "8px",
          },
          td: {
            px: "8px",
          },
        },
      },
    },
  },
});

export { theme };
