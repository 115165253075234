import React from "react";
import { HTMLChakraProps } from "@chakra-ui/system";
import Box, { BoxProps } from "../components/Box";

interface Options<T> {
  name: string;
  // eslint-disable-next-line no-unused-vars
  props: HTMLChakraProps<"div"> | ((props: T) => HTMLChakraProps<"div">);
}

export default function createSingleComponent<T = BoxProps>(ops: Options<T>) {
  const { props: baseProps, name } = ops;

  const Comp = React.forwardRef<any, T>((props, ref) => {
    if (typeof baseProps === "function") {
      const newProps = baseProps(props);
      return <Box {...newProps} />;
    }

    return <Box {...baseProps} {...props} ref={ref} />;
  });

  Comp.displayName = name;

  return Comp;
}
