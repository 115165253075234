import React from "react";
import Box from "../../components/Box";
import LazyImage from "../../components/LazyImage";
import axios from "axios";

export interface Partner {
  id: number;
  acf: {
    logos: string[];
  };
}

async function fetchPartners() {
  const result = await axios.get<Partner[]>(
    `https://ali-bolt.kr-asia.com/wp-json/wp/v2/partners?&per_page=50`
  );

  return result.data;
}

function HomePartner() {
  const [partners, setPartners] = React.useState<string[]>([]);

  React.useEffect(() => {
    fetchPartners()
      .then((d) => {
        if (d[0]) {
          setPartners(d[0].acf.logos);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, []);

  return (
    <Box
      id="partners"
      as="section"
      bg="primary"
      pt={{ base: "100px", xl: "170px" }}
      pb={{ base: "50px", xl: "50px" }}
      overflow="hidden"
    >
      <Box
        textAlign="center"
        as="h2"
        fontWeight={700}
        fontSize={{ base: "20px", md: "25px", xl: "26px" }}
        lineHeight={{ base: "38px", xl: "38px" }}
        letterSpacing="0.02em"
        color="textOnPrimary"
      >
        VC PARTNERS
      </Box>
      <Box
        mt={{ base: "40px", xl: "60px" }}
        px="24px"
        maxWidth="1600px"
        mx="auto"
      >
        <Box
          justifyContent="center"
          display="flex"
          flexWrap="wrap"
          mx={{ base: "-40px", xl: "-56px" }}
          sx={{
            "& img": {
              width: {
                base: "128px",
                md: "145px",
                xl: "190px",
              },
              mx: { base: "40px", xl: "56px" },
            },
          }}
        >
          {partners.map((l) => {
            return <LazyImage key={l} src={l} alt="" />;
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default HomePartner;
