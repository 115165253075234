// i18next-extract-mark-ns-start index

import axios from "axios";
import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import "../css/index.css";
import Header, { menus } from "../components/Header";
import Main from "../layouts/Main";
import HomeAbout from "../sections/HomeAbout";
import HomeProcess from "../sections/HomeProcess";
import HomeJudges from "../sections/HomeJudges";
import HomePartner from "../sections/HomePartner";
import Box from "../components/Box";
import HomeSelectionPitch from "../sections/HomeSelectionPitch";
import HomeCommunityPartner from "../sections/HomeCommunityPartner";
import { Button } from "../components/Button";
import HomeEvent from "../sections/HomeEvent";
import { Link as IDLink } from "react-scroll";

interface Links {
  id: number;
  acf: {
    registration_link: string;
  };
}

async function fetchLinks() {
  const result = await axios.get<Links[]>(
    "https://ali-bolt.kr-asia.com/wp-json/wp/v2/registrations"
  );
  return result.data;
}

const IndexPage = () => {
  const [links, setLinks] = React.useState<Links[] | undefined>();

  React.useEffect(() => {
    fetchLinks()
      .then((r) => {
        setLinks(r);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/banner-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  // const LINK_CTA_SIGNUP =
  //   "https://36krglobal.typeform.com/ACGSA-indo1?utm_source=site&utm_medium=blueCTA&utm_campaign=ACGSA";

  return (
    <Main>
      <Header link={links?.[0]?.acf.registration_link} />
      <Box pt="72px">
        <Box bg="primary">
          <Box mx="auto" maxWidth="1920px">
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Cloud Alibaba - Asia Forward"
            />
          </Box>
        </Box>
        <Box
          py="14px"
          px="16px"
          bg="#2D40FF"
          display="flex"
          flexDirection={{
            base: "column",
            xl: "row",
          }}
          alignItems="center"
          justifyContent="center"
          fontSize={{ base: "14px", xl: "18px" }}
          lineHeight={{ base: "18px", xl: "20px" }}
          fontWeight={700}
          color="#fff"
        >
          Join Alibaba Cloud x KrASIA Global Startup Accelerator
          <Box
            ml={{ base: "0px", xl: "40px" }}
            mt={{ base: "10px", xl: "0px" }}
          >
            <Button
              fontSize={{ base: "14px", xl: "18px" }}
              lineHeight={{ base: "18px", xl: "20px" }}
              height="40px"
              variant="ghost"
            >
              <IDLink to={`${menus[2].id}`} smooth={true}>
                Register now
              </IDLink>
            </Button>
          </Box>
        </Box>
        <HomeAbout />
        <HomeProcess />
        <HomeSelectionPitch />
        <HomeEvent />
        <HomeJudges />
        <HomePartner />
        <HomeCommunityPartner />
        <Box
          px={{ xl: "90px" }}
          fontSize="14px"
          lineHeight="24px"
          as="footer"
          height={{ base: "90px", xl: "72px" }}
          bg="#0B0D17"
          color="#D9DBE1"
          textAlign="center"
          display="flex"
          flexDir={{ base: "column", xl: "row" }}
          justifyContent={{ base: "center", xl: "flex-start" }}
          alignItems={{ xl: "center" }}
        >
          <Box as="p">
            Reach us at{" "}
            <Box
              as="a"
              textDecoration="underline"
              href={`mailto:alibabacloud@kr-asia.com`}
            >
              alibabacloud@kr-asia.com
            </Box>{" "}
            for any enquiries
          </Box>
          <Box as="p" ml={{ xl: "auto" }}>
            © 2021 36Kr Global. All rights reserved
          </Box>
        </Box>
      </Box>
    </Main>
  );
};

export default IndexPage;
